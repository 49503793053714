$(document).ready(function(){
	// HTML markup implementation, overlap mode, initilaize collapsed
	$('#menu').multilevelpushmenu({
	//containersToPush: [$( '#pushobj' )],
    direction: 'ltr',
    mode: 'cover',
    collapsed: true,
    fullCollapse: true,
    menuWidth: '280px',
    menuHeight: '100%',
    swipe: 'touchscreen',
  
    // Add menu Styling
    wrapperClass: 'mlpm_w',
    menuInactiveClass: 'mlpm_inactive',
    menuActiveClass: 'mlpm_active',
    backItemIcon: 'glyphicon glyphicon-chevron-right',
    groupIcon: 'glyphicon glyphicon-chevron-left', 
    
    onItemClick: function() {
        // First argument is original event object
        var event = arguments[0],
            // Second argument is menu level object containing clicked item (<div> element)
            $menuLevelHolder = arguments[1],
            // Third argument is clicked item (<li> element)
            $item = arguments[2],
            // Fourth argument is instance settings/options object
            options = arguments[3];

        // Anchor href
        var itemHref = $item.find( 'a:first' ).attr( 'href' );
        // Redirecting the page
        location.href = itemHref;
    },
    onMenuReady: function() {
      $('#menu').show();
    }
    
	});
  
  
  // Full Expand
  $('#mlpm_btn').click(function(){
    $('#menu').multilevelpushmenu('expand');
    $(document.body).toggleClass('responsive-menu');
    $('#pushobj').toggleClass('pageoverlay-on');
  });

  // Full Collapse
  $('#menu h2').click(function() {
    $('#menu').multilevelpushmenu('collapse');
    $(document.body).toggleClass('responsive-menu');
    $('#pushobj').toggleClass('pageoverlay-on');
  });
  $('#menu .cursorPointer').click(function() {
    $(document.body).toggleClass('responsive-menu');
    $('#pushobj').toggleClass('pageoverlay-on');
  }); 
  /*$('.pageoverlay-on').click(function() {
    console.log('pageoverlay-on Clicked');
    $('#menu').multilevelpushmenu('collapse');
    $(document.body).toggleClass('responsive-menu');
  }); */
  
});

$(window).resize(function () {
    $('#menu').multilevelpushmenu('redraw');
});